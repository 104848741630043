import React from "react";
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  // Redirect
} from "react-router-dom";

import { ToastContainer as DefaultToastContainer } from "react-toastify";

// Import Contexts
// import { theme, ThemeProvider } from "./context/ThemeProvider";
import SuspenseFallback from "./components/SuspenseFallback";
import ErrorBoundary from "./components/ErrorBoundary";
import NoPage from "./components/UnknownPage";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

// Other assets
import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";

// Import Layouts
import Home from "./layouts/Home";

const HomePage = React.lazy(() => import("./pages/Home"));
const Farmers = React.lazy(() => import("./pages/Farmers"));
const Partners = React.lazy(() => import("./pages/Partners"));
const Blog = React.lazy(() => import("./pages/Blog"));
const Article1 = React.lazy(() => import("./pages/Article1"));
const Article2 = React.lazy(() => import("./pages/Article2"));

// create a default container so we can override the styles
const ToastContainer = (props) => (
  <DefaultToastContainer style={{ zIndex: "1900" }} {...props} />
);

function App() {
  // const permissions = useSelector((state) => state.AuthReducer?.permissions);

  return (
    // <ThemeProvider theme={theme}>
    <ErrorBoundary>
      <React.Suspense fallback={<SuspenseFallback />}>
        <Router>
          <NavBar/>
          <Switch>
            {/* <Route exact path="/" element={<Login />} /> */}

            <Route path="/" element={<Home />}>
              <Route index element={<HomePage />} />

            </Route>
            <Route path="/farmers" element={<Farmers />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/revolutionizing-agribusinesses" element={<Article1 />} />
            <Route path="/blog/symbolic-seed-planting" element={<Article2 />} />
            <Route path="*" element={<NoPage />} />
          </Switch>
        </Router>
        <Footer />
        <ToastContainer />
      </React.Suspense>
    </ErrorBoundary>
    // </ThemeProvider>
  );
}

export default App;